import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import FeatureGrid from "components/ui/base/FeatureGrid";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import RegisterForTemplateBuilder from "components/ui/extended/RegisterForTemplateBuilder";
import Customers from "components/ui/extended/Customers";
import VideoPreview from "components/ui/base/VideoPreview";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import FullWidthCarousel from "components/ui/base/FullWidthCarousel";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import { EmailTemplate } from "app-types/template";
import { USE_EMAIL_TEMPLATE_BUILDER } from "constants/paths";
import FullWidthImage from "components/ui/base/FullWidthImage";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";

interface EmailTemplateBuilderPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    allEmailsTemplates: {
      nodes: EmailTemplate[];
    };
  };
  location: Location;
}

export default ({ data, location }: EmailTemplateBuilderPageProps) => {
  const { t } = useTranslation();
  const emailTemplates = data.allEmailsTemplates.nodes;

  return (
    <GlobalWrapper
      withLayout
      layoutSettings={{ withCta: false }}
      location={location}
    >
      <SEO
        title={t("pages.email-template-builder.seo.title")}
        description={t("pages.email-template-builder.seo.description")}
        image={{
          relativePath: "meta/email-template-builder.jpg",
          alt: "Free Email template Builder",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.email-template-builder.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.email-template-builder.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
        ]}
      />

      <PlainHeaderWithVideo
        title={t("pages.email-template-builder.title")}
        description={t("pages.email-template-builder.description")}
        titleMaxWidth={940}
        descriptionMaxWidth={900}
        ctaButton={{
          icon: "arrow-right",
          show: true,
          text: t("pages.email-template-builder.cta"),
          path: USE_EMAIL_TEMPLATE_BUILDER,
        }}
        video={{
          path: "pages/email-template-builder/email-template-builder.mp4",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Free Email Template Builder",
            path: "/email-template-builder/",
          },
        ]}
      />
      <ImageWithDescription
        title={t("pages.email-template-builder.1.title")}
        description={t("pages.email-template-builder.1.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/create-beautiful-email-titles-with-ai.png",
          alt: t("pages.email-template-builder.1.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.2.title")}
        description={t("pages.email-template-builder.2.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/email-template-builder-free-responsive.png",
          alt: t("pages.email-template-builder.2.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <FullWidthImage
        title={t("pages.email-template-builder.3.title")}
        description={t("pages.email-template-builder.3.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/html-email-template-builder-with-upload-folders.jpg",
          alt: t("pages.email-template-builder.3.title"),
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      {/*<EmailTemplates showMore templates={emailTemplates.slice(0, 10)} />*/}

      <FullWidthCarousel
        title={t("pages.email-template-builder.4.title")}
        description={t("pages.email-template-builder.4.subtitle")}
        images={[0, 1, 2, 3, 4, 5, 6].map((image) => {
          return {
            relativePath: `pages/email-template-builder/email-template-builder-block-${image}.png`,
            alt: t("pages.email-template-builder.4.alt"),
          };
        })}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.5.title")}
        description={t("pages.email-template-builder.5.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/email-template-builder-free-Drag-and-drop-editor.jpg",
          alt: t("pages.email-template-builder.5.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.6.title")}
        description={t("pages.email-template-builder.6.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/drag-n-drop-editor-to-create-responsive-email-with-columns.jpg",
          alt: t("pages.email-template-builder.6.title"),
          position: "right",
          fullWidth: true,
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.7.title")}
        description={t("pages.email-template-builder.7.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/email-template-builder-free-responsive-design.jpg",
          alt: t("pages.email-template-builder.7.title"),
          position: "left",
          fullWidth: true,
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <FullWidthImage
        title={t("pages.email-template-builder.8.title")}
        description={t("pages.email-template-builder.8.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/html-email-template-builder.jpg",
          alt: t("pages.email-template-builder.8.title"),
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.9.title")}
        description={t("pages.email-template-builder.9.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/download-html-code-from-free-email-template-builder.png",
          alt: t("pages.email-template-builder.9.title"),
          position: "left",
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.10.title")}
        description={t("pages.email-template-builder.10.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/email-editor-with-free-images.png",
          alt: t("pages.email-template-builder.10.title"),
          position: "right",
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <FullWidthImage
        title={t("pages.email-template-builder.11.title")}
        description={t("pages.email-template-builder.11.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/email-template-builder-preview-dark-mode.jpg",
          alt: t("pages.email-template-builder.11.title"),
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.12.title")}
        description={t("pages.email-template-builder.12.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/send-preview-email-editor-with-free-images.png",
          alt: t("pages.email-template-builder.12.title"),
          position: "right",
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <ImageWithDescription
        title={t("pages.email-template-builder.13.title")}
        description={t("pages.email-template-builder.13.subtitle")}
        image={{
          relativePath:
            "pages/email-template-builder/autosave-email-editor-with-free-images.png",
          alt: t("pages.email-template-builder.13.title"),
          position: "left",
        }}
        cta={{
          link: USE_EMAIL_TEMPLATE_BUILDER,
          text: t("pages.email-template-builder.cta"),
        }}
      />

      <VideoPreview
        title={t("pages.email-template-builder.14.title")}
        paragraph={t("pages.email-template-builder.14.subtitle")}
        storiesPosition={"right"}
        stories={[
          {
            relativePath:
              "templates/templates/email/save-and-organize-email-templates.mp4",
          },
        ]}
      />

      <FeatureGrid
        title={t("pages.email-template-builder.others.title")}
        description={t("pages.email-template-builder.others.description")}
        centered={false}
        sections={[
          {
            title: t("pages.email-template-builder.others.newsletter.title"),
            description: t(
              "pages.email-template-builder.others.newsletter.description"
            ),
            image: {
              alt: "Newsletter",
              relativePath: "icons/newsletter.png",
            },
            links: [
              {
                text: t("pages.email-template-builder.others.newsletter.cta"),
                path: "/email-marketing/",
              },
            ],
          },
          {
            title: t("pages.email-template-builder.others.funnel.title"),
            description: t(
              "pages.email-template-builder.others.funnel.description"
            ),
            image: {
              alt: "Funnel",
              relativePath: "icons/funnel-marketing.png",
            },
            links: [
              {
                text: t("pages.email-template-builder.others.funnel.cta"),
                path: "/marketing-funnel/",
              },
            ],
          },
        ]}
      />

      <Customers />

      <RegisterForTemplateBuilder />

      <QuestionsAndAnswers
        title={t("pages.email-template-builder.QA.title")}
        description={t("pages.email-template-builder.QA.description")}
        elements={Array.from(Array(3).keys()).map((i) => {
          return {
            question: t(`pages.email-template-builder.QA.${i + 1}.question`),
            answer: t(`pages.email-template-builder.QA.${i + 1}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

export const EmailTemplateBuilderQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/email-template-builder.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allEmailsTemplates {
      nodes {
        id
        name
        subject
        thumbnail_url
        description
        created_at
        community_email_template_id
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
  }
`;
